export default {
  "client": {
    "blindbox": {
      "bag": "client/blindbox/bag",
      "job": "client/blindbox/job",
      "order": "client/blindbox/order",
      "order_box": "client/blindbox/order_box",
      "test": "client/blindbox/test"
    },
    "index": "client/index",
    "shop": {
      "cart": "client/shop/cart",
      "coupon": "client/shop/coupon",
      "order": "client/shop/order",
      "product": "client/shop/product",
      "product_category": "client/shop/product_category"
    }
  },
  "scms": {
    "admin": "scms/admin",
    "auth": "scms/auth",
    "banner": "scms/banner",
    "blindbox": {
      "bag": "scms/blindbox/bag",
      "order": "scms/blindbox/order",
      "order_box": "scms/blindbox/order_box"
    },
    "config": "scms/config",
    "coupon": "scms/coupon",
    "misc": "scms/misc",
    "order": "scms/order",
    "product": "scms/product",
    "product_category": "scms/product_category",
    "promotion": "scms/promotion",
    "promotion_category": "scms/promotion_category",
    "role": "scms/role",
    "user": "scms/user"
  },
  "tools": "tools"
};
