import * as scms from "./scms/auth";
import * as utils from '@/utils/utils'
import {setToken} from "../../../service";
import * as client from "./client/index";

let inited = false;

export default async () => {
  if (!inited) {
    inited = true;

    let info;
    try {
      // client
      info = await client.infos.telByCodeGetToken.make();
      setToken(info.data.data.token);
    } catch (e) {
      console.error(e);
    }
    try {
      // scms
      info = await scms.infos.login.make();
      utils.assert(info.data.code === 200, 'scms 登录失败');
      setToken(info.data.data.token, 'stoken');
    } catch (e) {
      console.error(e);
    }
  }
};
