import {infos as client } from '@/powder/user/client/index';
import {infos as bbAdmin } from '@/powder/user/scms/blindbox/bag';
import {infos as admin_user} from '@/powder/user/scms/user';
import * as utils from '@/utils/utils';

// check enough conch
export async function enoughConch(ctx, price) {
  let res = await client.getUserInfo.make();
  ctx.__userInfo = res;
  let conch = res.data.data.info.conch;
  if (conch < price/100 || conch < 50) {
    await admin_user.changeConch.make('default', res.data.data.info.uid);
    ctx.__userInfo.data.data.info.conch += 100; // 缓存的值相应的加上
  }
}

// shared by order.create
export async function conch(ctx, is_instant, price, allow_conch, count = 1) {
  await enoughConch(ctx, price);
  let bag = await bbAdmin.create.make('args', {
    start_time: parseInt(Date.now()/1000) - 3600,
    price,
    sale_type: is_instant ? "2" : "1",
    allow_conch: allow_conch,
  });
  let bag_id = bag.data.data.id;
  ctx.generated = {
    bag_id: bag_id,
    pay_choose: allow_conch,
    count,
  };
}

export async function conch_validate(ctx) {
  // 验证贝壳扣减
  let info = await client.getUserInfo.make();
  utils.assert(info.data.data.info.conch + 1 === ctx.__userInfo.data.data.info.conch, "贝壳消费错误");
  return false;
}

export async function conch_validate_restore(ctx, try_count = 1) {
  // 验证贝壳恢复
  let lastInfo = client.getUserInfo.lastResult;
  let suc = false;
  let current;
  for (let i = 0; i < try_count; i++) {
    let info = await client.getUserInfo.make();
    current = info.data.data.info.conch;
    if (current === lastInfo.data.data.info.conch) {
      suc = true;
      break;
    }
    await utils.sleep(1000);
  }
  let msg = "贝壳恢复错误, lastConch：" + lastInfo.data.data.info.conch + ", current：" + current;
  utils.assert(suc, msg);
  return false;
}

export async function conch_expect(expectedConch, msg, waitCount = 5) {
  // 不能用异步验证，避免其他操作影响贝壳值
  let conch;
  let suc = false;
  for (let i = 0; i < waitCount; i++) {
    await utils.sleep(1000);
    let res = await client.getUserInfo.make();
    conch = res.data.data.info.conch;
    if (expectedConch === conch) {
      suc = true;
      break;
    }
  }
  let info = msg + ", expectedConch：" + expectedConch + ", current：" + conch;
  utils.assert(suc, info);
}
