/**
 * @auth zhc
 * @description 全局共用方法函数
 * **/
import {cityCode} from "./src/utils/cityCode";
import {getServiceInfo} from '@/api/client/index';
import { Message } from 'element-ui';

const uni = {};

//获取当前定位
export const getCityName = function () {
        uni.getLocation({
            type: 'gcj02',
            success: function (res) {
                console.log(res)
                return res
            }
        });
    }

//遍历数组
export const mapArr = function (val) {
    val.map((item) => {
        if (item.check == true) {
            item.check = false
        }
        return val
    })
}
//拨打电话方法api
export const dialPhone = function (phone) {
    uni.makePhoneCall({
        phoneNumber: phone
    })
}


//定位授权方法
export const getLieAuth = function () {
    // #ifdef MP-WEIXIN
    uni.getSetting({
        success(res) {
            // console.log(res)
            if (!res.authSetting['scope.userLocation']) {
                // 未授权
                uni.authorize({
                    scope: 'scope.userLocation',
                    success() { //1.1 允许授权
                        uni.getLocation()
                    },
                    fail() {    //1.2 拒绝授权
                        console.log("你拒绝了授权，无法获得周边信息")
                    }
                })
            } else {
                // 已授权 ..(获取位置信息)
            }
        }
    });
    // #endif
}


//监听应用所处网络环境
export const getNetwork = function () {
    uni.getNetworkType({
        success: function (res) {
            console.log(res)
            if (res.networkType == 'none') {
                Message.error('网络连接失败');
                return
            }
            if (res.networkType == '2g' || res.networkType == '3g') {
                Message.error('网络连接失败');
                return
            }
        }
    })
}

// 获取token
export const getToken = function (tokenName = 'token') {
    return window.localStorage.getItem(tokenName);
}

//存储token
export const setToken = function (val, tokenName = 'token') {
     window.localStorage.setItem(tokenName, val);
}

// 时间戳处理
export const showTime = function (timestamp) {
    let stamp = parseInt(timestamp) * 1000, date, Y, M, D, h, m, s;
    date = new Date(stamp);
    Y = date.getFullYear() + '/';
    M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
    D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return (Y + M + D + h + m + s);
}

// 时间戳处理 年月日
export const showDate = function (timestamp) {
    let stamp = parseInt(timestamp) * 1000, date, Y, M, D;
    date = new Date(stamp);
    Y = date.getFullYear() + '.';
    M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '.';
    D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    return (Y + M + D);
}

// 订单状态规划显示状态
export const showOrderStatus = function (orderStatus, payStatus, allotStatus) {
    // 4 5 10
    // 1
    // 9
    // 6 7
    // 8
    if (orderStatus === 0) return {status: 2, msg: '订单已取消'};
    if (payStatus === 0) return {status: 1, msg: '等待付款'};
    if (payStatus === 2) return {status: 4, msg: '退款中'};
    if (payStatus === 3) return {status: 10, msg: '退款被拒绝'};
    if (payStatus === 4) return {status: 5, msg: '已退款'};
    if (payStatus === 1 && allotStatus === 0) return {status: 6, msg: '等待派单'};
    if (payStatus === 1 && allotStatus === 1) return {status: 7, msg: '已派单，等待接单'};
    if (payStatus === 1 && allotStatus === 2) return {status: 8, msg: '服务完成'};
    if (payStatus === 1 && allotStatus === 4) return {status: 9, msg: '服务人员已接单'};
    if (payStatus === 1 && allotStatus === 5) return {status: 9, msg: '服务人员已到达'};
    if (payStatus === 1 && allotStatus === 6) return {status: 9, msg: '服务中'};
    return {};
}

export const getCityCode = function (city) {
    cityCode.forEach(function(i,k){
        i.cities.forEach(function(n,m){
            if (n.city === city) {
                uni.setStorageSync('cityCode', n);
                return n;
            }
        })
    })
}

export const gotoServiceInfo = function (obj,callBack) {
    let payload = obj.payload;
    if (payload.type === 1) {
        let res = getServiceInfo(obj.payload.id);
        // 服务详情存储vuex 然后跳转
        res.then(function(e){
            // console.log(e);
            callBack(e.data.data.info);
            uni.navigateTo({
                url: '/pages/detailsProduct/detailsProduct'
            });
        });
    }
}
export const isObject = function (source) {
  return Object.prototype.toString.call(source) === '[object Object]';
};

