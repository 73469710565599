/*eslint-disable*/
import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../powder/consts';
import * as _ from 'lodash';
import { avd } from '../powder/avalidate';
import {getURLParam, getParam, setURLParam} from "../utils/utils";
import * as utils from '../utils/utils'
import allIndex from '../powder/generated/__index';
import{ isObject } from '../../service';
import ParamsModal from './components/UrlParamsModal';
import {setTrackID} from '../utils/request';

// url 模板
let user_params; // 用户设置的参数，发送 request 之后保存入 localStorage，mounted 时加载
let gene_ways; // 用户对于每个接口选择的生成方式

// api 文件模块动态加载，注意与 currentM 同步变动
let api = getURLParam('select-module');
let apiUsers;

// module include & exclude config
let mi = getParam('mi');
mi = mi ? mi.split(':') : null;
let me = getParam('me');
me = me ? me.split(':') : null;
// url include & exclude config
let ui = getParam('ui');
ui = ui ? ui.split(':') : null;
let ue = getParam('ue');
ue = ue ? ue.split(':') : null;
setTrackID(getParam('tid'));

export default {
  components: {
    ParamsModal,
  },
  data() {
    return {
      checkRequestOption: [],
      checkResultDisplayType: ['chkAsSample', 'chkFormat'],
      select:'',
      selectGene: '',
      selectApi:{},
      tableShow:false,
      filter: '',
      requestResult: '',
      currentM: api, // 选中的api模块
      chkAsSample: true,
      chkFormat: true,
      chkResultArr: ['chkAsSample', 'chkFormat'],
      batchIgnore: ['/scmsapi/auth/login','/scmsapi/auth/logout','/scmsapi/auth/updatePwd','/scmsapi/role/setMenu'],
      urlParamsConfig: {
        visible: false,
        urlParams: {},
      }
    }
  },
  computed: {
    selectApiList() {
      apiUsers = this.loadModule(this.currentM).module;
      if (apiUsers) {
        const infos = apiUsers.infos;
        const data = Object.keys(infos).map((item) => {
          return {
            ...infos[item],
            value: item,
            label: `【${infos[item].desc}--${infos[item].name}】${infos[item].url}`
          };
        });
        return data
      }
      return [];
    },
    selectDetailApiUrl() {
      const { url } = this.selectApiList.find(({ value }) => value === this.select) || {};
      return url;
    },
    geneList() {
      let result = [];
      if (!this.selectApi) {
        return result;
      }
      let genes = this.selectApi.geneArgs;
      if (Array.isArray(genes)) {
        for (let i = 0; i < genes.length; i++) {
          let gene = genes[i];
          if (gene.type !== INNER) {
            result.push(gene);
          }
        }
      } else if (typeof(genes) === 'function') {
        result.push({'scene': 'default', 'func': genes});
      }
      return result;
    },
    apiM() {
      function flatObject(obj = {}) {
        const result = [];
        Object.keys(obj).forEach((item, index) => {
          if (isObject(obj[item])) {
            const children = flatObject(obj[item]);
            result.push({
              id: item,
              value: index,
              label: item,
              children,
            })
          } else {
            result.push({
              id: item,
              value: obj[item],
              label: item,
            })
          }
        });
        return result;
      }
      return flatObject(allIndex);
    },
    gene() {
      return this.geneList[this.selectGene] || {};
    },
    geneOnly() {
      return this.gene.type === GENE_ONLY;
    },
    geneSkip() {
      return this.gene.skip && this.gene.skip();
    },
    geneDesc() {
      return this.gene? this.gene.desc : "";
    },
  },

  watch:{
    select(val){
      this.tableShow =false;
      if(val){
        this.selectApi = apiUsers.infos[this.select];
        if (this.selectApi) {
          if (!user_params[api]) {
            user_params[api] = {};
          }
          this.selectApi.user = user_params[api][this.select] || {};
          this.tableShow = true;
          setURLParam('select-api', this.select);
          document.title = this.selectApi.desc + " | " + this.select;
          this.selectGene = gene_ways[this.select] || 0;
        }
      }
    },
    selectGene(val) {
      gene_ways[this.select] = val;
      utils.setLSItem('powder.gene_ways', gene_ways);
    },
  },

  filters:{
    getType(value){
      return utils.getType(value);
    }

  },

  created() {
  },

  mounted() {
    this.select = getURLParam('select-api');
    user_params = utils.getLSItem('powder.user', {});
    gene_ways =utils.getLSItem('powder.gene_ways', {});
    document.addEventListener('keydown', this.keydown);
    // console.log(this.$route, this.$router);
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.keydown);
  },

  methods: {
    /** ================= powder ============================**/
    // load module under src/powder/user/
    loadModule(path, globalInit = false, clear = false) {
      if (!path) {
        return {
          module: null,
          initPromise: Promise.resolve()
        };
      }
      let module;
      try {
        module = require(`../powder/user/${path}`);
      } catch (e) {
        utils.showError("模块加载失败: " + path);
        console.error(e);
      }
      let r = {module};

      // 初始化调用顺序：根目录初始化模块 > ... > 目标模块，根目录到目标模块的路径上的所有 __init.js 依次加载并初始化
      r.initPromise = (async () => {
        const entries = path.split('/');
        if (clear && module.__clear) {
          await module.__clear();
        }
        if (globalInit) {
          for (let i = 0; i < entries.length; i++) {
            let dir = entries.slice(0, i).join('/');
            let dirModulePath = dir ? dir + "/__init" : "__init";
            try {
              let dirModule = require(`../powder/user/${dirModulePath}`);
              await dirModule.default();
            } catch (e) {
              // console.log(e);
            }
          }
        }
        // 最后初始化目标 module，无条件初始化
        if (module.__init) {
          // force, no cache
          await module.__init(globalInit);
        }
      })();
      return r;
    },
    async powder_gene() {
      //根据当前选择的接口和生成方式生成对应数据
      if (this.geneList.length) {
        try {
          await this.gene.func(this.selectApi);
          // 保证在生成函数中即使没有遵循响应式设置参数，也能渲染更新
          this.$forceUpdate();
        } catch (e) {
          console.error('参数生成失败', e);
        }
      }
    },
    async powder_request(validateReturns) {
      // 记录用户参数
      if (!user_params[api]) {
        user_params[api] = {};
      }
      user_params[api][this.select] = this.selectApi.user;
      utils.setLSItem('powder.user', user_params);
      let info;
      try {
        info = await this.selectApi.request(this.gene, null, validateReturns, false,
          this.checkRequestOption.includes('chkNoDef'));
      } catch (e) {
        if (e.origin) {
          console.error('request validation error: ', e.origin);
        }
        return;
      }
      // deep clone，后面可能还要通过 lastResult 引用这个结果
      let cloned = _.cloneDeep(info.data);
      // 如果作为 sample，则所有的数组（递归）都只保留一个元素
      if (this.chkAsSample) {
        const toSample = (cur) => {
          let curIsArray = Array.isArray(cur);
          for(let key of Object.keys(cur)) {
            let value = cur[key];
            let type = Array.isArray(value) ? "array" : typeof value;
            if (value && (type === "object" || type === "array")) {
              toSample(value);
            }
            if (curIsArray) {
              cur.length = 1;
              break;
            }
          }
        };
        toSample(cloned);
      }
      let formatSpace = this.chkFormat ? 2 : 0;
      this.requestResult = JSON.stringify(cloned, null, formatSpace) + '\n';
    },
    // 回调当前生成的 test 方法
    async geneTest() {
      this.gene.test(this.selectApi);
      let type = typeof(this.gene.test);
      if (type === "function") {
      } else {
        this.$message({
            message: 'gene.test 不是 function: ' + type,
            type: 'error'
        });
      }
    },
    // 回调当前接口的 test 方法
    async apiTest() {
      let type = typeof(this.selectApi.test);
      if (type === "function") {
        this.selectApi.test(this.selectApi);
      } else {
        this.$message({
            message: 'selectApi.test 不是 function: ' + type,
            type: 'error'
        });
      }
    },
    // 当前接口完整验证
    async powder_request_full(allGenes) {
      avd.clearV();
      await apiUsers.infos[this.select].fullValidate(false, allGenes ? null : this.gene);
      avd.startV();
    },
    // 指定模块的所有接口全量验证
    async request_module_core(module, stopOnError = true) {
      let result = {
        'suc': 0,
        'fail': 0,
      };
      for(let key in module.infos) {
        let api = module.infos[key];
        // logout 等接口不要调用
        if (this.batchIgnore.includes(api.url)) {
          continue;
        }
        if (!utils.shouldInclude(ui, ue, api.url)) {
          console.log('ignored, url: ', api.url);
          continue;
        }
        if (await api.fullValidate(stopOnError)) {
          result.suc++;
        } else {
          result.fail++;
        }
      }
      return result;
    },
    async powder_request_one_module() {
      let totalBegin = Date.now();
      let paramsArr = utils.looseJsonParse(utils.getLSItem('batchParams') || '[{}]');
      for (let params of paramsArr) {
        console.log("============== batch params: ", params);
        utils.setBatchParams(params);
        console.log("-------------- begin request one module test ------------", utils.dateNow());
        let begin = Date.now();
        avd.clearV();
        let one = await this.request_module_core(apiUsers, false);
        avd.startV();
        console.log(`fail/suc api(s): ${one.fail}/${one.suc}`);
        console.log("-------------- end request one module test ------------",
          utils.dateNow(), "耗时：" + (Date.now() - begin) / 1000 + "s");
      }
      console.log("============== total 耗时：" + (Date.now() - totalBegin) / 1000 + "s");
      // reset batch params
      utils.setBatchParams({});
    },

    moduleRecur(moduleArr) {
      let modules = [];
      for(let i in moduleArr) {
        let module = moduleArr[i];
        if (module.children) {
          modules.push(...this.moduleRecur(module.children));
        } else {
          modules.push(module.value);
        }
      }
      return modules;
    },
    // 所有模块的所有接口全量验证
    async powder_request_all_modules() {
      let totalBegin = Date.now();
      let paramsArr = utils.looseJsonParse(getParam('batchParams') || '[{}]');
      for (let params of paramsArr) {
        console.log("============== batch params: ", params);
        utils.setBatchParams(params);

        let begin = Date.now();
        console.log("-------------- begin request all module test ------------", utils.dateNow());
        let modules = this.moduleRecur(this.apiM);

        let result = {
          'suc': 0,
          'fail': 0,
        };
        avd.clearV();
        for (let i in modules) {
          let modulePath = modules[i];
          let flag;
          if (!utils.shouldInclude(mi, me, modulePath)) {
            console.log('ignored, modulePath: ', modulePath);
            continue;
          }
          console.log(`[=============== module:${modulePath} ======================]`);
          const r = this.loadModule(modulePath);
          await r.initPromise;
          let module = r.module;
          let one = await this.request_module_core(module, false);
          result.suc += one.suc;
          result.fail += one.fail;
        }
        avd.startV();
        console.log(`fail/suc api(s): ${result.fail}/${result.suc}`);
        console.log("-------------- end request all module test ------------",
          utils.dateNow(), "耗时：" + (Date.now() - begin) / 1000 + "s");
      }
      console.log("============== total 耗时：" + (Date.now() - totalBegin) / 1000 + "s");
      // reset batch params
      utils.setBatchParams({});
    },
    keydown(event) {
      let c = String.fromCharCode(event.keyCode);
      if (c === 'Z' && event.altKey) {
        this.powder_gene();
        event.preventDefault();
      } else if (c === 'X' && event.altKey || (event.key === 'Enter' && event.ctrlKey)) {
        this.powder_request(false);
        event.preventDefault();
      } else if (c === 'C' && event.altKey) {
        this.powder_request(true);
        event.preventDefault();
      } else if (c === 'F' && event.altKey) {
        // this.$refs.filter.$el.focus(); 无效，因为嵌套结构为 uni-input > div > input
        // document.querySelector(".filter input").focus();
        this.$refs.selApi.focus();
        event.preventDefault();
      } else if (c === 'A' && event.altKey) {
        this.geneTest();
        event.preventDefault();
      } else if (c === 'S' && event.altKey) {
        this.apiTest();
        event.preventDefault();
      }
    },
    copy(data) {
      if (!data) {
          this.$message({
              message: '复制内容不能为空',
              type: 'error'
          });
        return;
      }
      const textareaEl = document.createElement('textarea');
      textareaEl.setAttribute('readonly', 'readonly');
      textareaEl.value = data;
      document.body.appendChild(textareaEl);
      textareaEl.select();
      const res = document.execCommand('copy');
      document.body.removeChild(textareaEl);
        this.$message({
            message: '复制成功',
            type: 'success'
        });
      return res;
    },
    openUrlParamsModal() {
      const urlParams = Object.fromEntries(new URL(location.href).searchParams);
      const storageLen = localStorage.length;
      const storageParams = {};
      if (storageLen) {
        for (let i = 0; i < storageLen; i++) {
          const key = localStorage.key(i);
          storageParams[`storage_${key}`] = localStorage.getItem(key);
        }
      }
      this.urlParamsConfig.urlParams = {
        ...urlParams,
        ...storageParams,
      };
      this.urlParamsConfig.visible = true;
    },
    closeParamsModal() {
      this.urlParamsConfig.visible = false;
    },
    changeUrlParams(val) {
      localStorage.clear();
      const {url, storage} = val;
      storage.forEach(({key, value}) => {
        localStorage.setItem(key, value);
      });
      const paramsStr = url
          .map(({key, value}) => (`${key}=${encodeURIComponent(value)}`))
          .join('&');
      // 不用 nextTick，对逻辑也无影响，只是保证后续代码的执行能被设置断点
      this.$nextTick(() => {
        let url = new URL(location);
        url.search = `?${paramsStr}`;
        location.replace(url);
      });
    },
    // 结束掉员工身上所有的订单，让员工空闲
    async freeStaff() {
      let info = await apistaff.orderList(1, 100);
      info.data.data.info.forEach((item) => {
        if (item.status_staff >= 4 && item.status_staff <= 6) { // 4=确认接单,5=到达服务地,6=开始服务
          order.toFinishOrder(item.orderID,item.status_staff);
        }
      });
    },
    // 接口-生成函数清单，支持过滤器(mi & me)
    async listAll() {
      let results = [];
      let modules = this.moduleRecur(this.apiM);
      for (let modulePath of modules) {
        if (!utils.shouldInclude(mi, me, modulePath)) {
          console.log('ignored, modulePath: ', modulePath);
          continue;
        }
        let module = require(`../powder/user/${modulePath}`);
        for(let key in module.infos) {
          let api = module.infos[key];
          if (!utils.shouldInclude(ui, ue, api.url)) {
            console.log('ignored, url: ', api.url);
            continue;
          }
          results.push(`【${modulePath}】【${api.desc.trim()}--${api.name}】${api.url}`);
          for (let gene of api.geneArgs) {
            results.push(`  scene: ${gene.scene}\n  desc: ${gene.desc}`);
          }
        }
      }
      this.requestResult = results.join('\n') + "\n\n";
    },
    async globalInit() {
      console.log("-------------- begin global init all modules ------------");
      let modules = this.moduleRecur(this.apiM);
      for (let i in modules) {
        let modulePath = modules[i];
        if (!utils.shouldInclude(mi, me, modulePath)) {
          console.log('ignored, modulePath: ', modulePath);
          continue;
        }
        console.log(`[=============== module:${modulePath} ======================]`);
        const r = this.loadModule(modulePath, true, true);
        await r.initPromise;
      }
      console.log("-------------- end global init all modules ------------");
    },
    // 当前请求返回结果选项变化
    requestResultGroupChange(checkResultDisplayType) {
      this.chkAsSample = checkResultDisplayType.includes('chkAsSample');
      this.chkFormat = checkResultDisplayType.includes('chkFormat');
    },
    // 复制 default/generated 值到 user
    copyToUser(key, col) {
      let api = this.selectApi;
      let value = col === "def" ? api.defaults[key] : api.generated[key];
      if (typeof (value) !== 'string') {
        value = JSON.stringify(value, null, 2);
      }
      this.$set(api.user, key, value);
    },

    //选择api模块事件
    treeItemClick(item, nodes) {
      if (nodes.isLeaf) {
        const { value } = item;
        api = this.currentM = value;
        setURLParam('select-module', value);
      }
    },
  }
}

